import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import LocationsMap from './locations-map';
import LocationsNav from './locations-nav';
import ContactForm from './contact-form';
import LocationsDropdown from './locations-dropdown';
import './index.scss';

export default function ContactUsPage ({ path, data, pageContext, location: navLocation }) {
  const {
    title, heroHeading, modules, metaDescription, heroBackgroundImage, formHeading, heading,
    successMessage, ogImage
  } = data.contentfulContactUsPage;

  useEffect(() => {
    if (navLocation.state?.scrollY) {
      window.scrollTo({ top: navLocation.state.scrollY });
    }
  }, [ navLocation ]);

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className="hero contact-us-hero">
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container hero__inner">
          <h2>{heroHeading?.heroHeading}</h2>
        </div>
      </section>

      <div className="contact-us-content">
        <section className="contact-us-heading">
          <div className="container contact-us-heading__inner">
            <h2>{heading?.heading}</h2>
          </div>
        </section>

        <LocationsDropdown
          locations={data.allContentfulLocation.nodes}
          contactUsPageId={pageContext.id}
          navLocation={navLocation}
        />
        <div className="container">
          <LocationsNav
            locations={data.allContentfulLocation.nodes}
            contactUsPageId={pageContext.id}
          />
        </div>
        <div className="container">
          <LocationsMap
            locations={data.allContentfulLocation.nodes}
            contactUsPageId={pageContext.id}
          />
        </div>
        <div className="container form-container">
          <ContactForm formHeading={formHeading} successMessage={successMessage} />
        </div>
      </div>
      {renderModules(modules)}
    </Layout>
  );
}

export const pageQuery = graphql`
query ContactUsPageById($id: String!) {
  contentfulContactUsPage(id: {eq: $id}) {
    title { title }
    metaDescription { metaDescription }
    formHeading
    heroHeading { heroHeading }
    heading { heading }
    successMessage { raw }

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }

  allContentfulLocation {
    nodes {
      name { name }
      slug
      location { lat, lon }
    }
  }
}
`;
